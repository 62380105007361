<style lang="scss">
#f-payement {
  .toast .toast-message {
    z-index: 99999999999 !important;
  }
  label[for="iframesCardNumber"],
  label[for="iframesCardExpiration"],
  label[for="iframesCardCvv"] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #555;
    line-height: 1.5;
  }
  .cvv {
    font-family: sans-serif;
    box-sizing: border-box;
    display: block;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    width: 100%;
  }
  #ss-banner {
    background: transparent
      url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true)
      no-repeat left center;
    height: 40px;
    background-size: 280px 34px;
    margin-bottom: 7px;
  }

  user agent stylesheet div {
    display: block;
  }

  .form-row {
    margin-top: 10px;
  }

  .form-wrapper {
    display: block;
    width: 300px;
    margin: 10px auto;
  }
  .alert-fixed {
    position: fixed;
    top: 6%;
    left: 0;
    width: 100%;
    background: #dc3545 !important;
    text-align: center;
    z-index: 99999999;
    height: 5%;
  }
  #payment-info {
    display: none;
  }
  #loading-payment {
    margin-top: 25%;
  }
  #tokenframe {
    width: 100% !important;
  }
  @media (max-width: 400px) {
    .paymemt-label {
      font-size: 0.8rem !important;
    }
  }
  .hidden {
    display: none;
  }
  #cc_error {
    color: red;
  }
  .btn {
    border-radius: 0;
  }
  .alert-danger-dim {
    background: #f7cece90;
  }

  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
  .payment-type {
    list-style: none;
    padding: 0;
    li {
      padding: 10px;
      background: #d0cee5ad;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #d0cee5d6;
      }
      &.active {
        border: 1px solid #211a68;
        box-sizing: border-box;
        // &:hover {
        //   background: rgb(87, 86, 86);
        //   color: white;
        // }
      }
      .checked-payment {
        position: absolute;
        height: 40px;
        width: 30px;
        top: 0;
        left: 0;
        border-top: 0px solid transparent;
        border-bottom: 41px solid transparent;
        border-left: 40px solid #211a68;
      }
      label {
        margin: 0;
        // margin-left: 5%;
        span {
          margin-left: 10px;
          font-weight: 500;
          font-size: 14px;
        }
        i {
          color: black;
        }
      }
    }
  }
}
</style>
<template>
  <section id="f-payement">
    <v-countries-modal
      :li-scrolls="id_scroll_list_country"
      :show-modal="showCountriesWithPhoneCode"
      v-on:closemodal="toggleCountriesPhoneCode"
      :type="true"
    ></v-countries-modal>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-5" v-if="getMobileLocationJobList.rates.service.service_type_code != 'fdle'">
        <table class="table table-bg-success">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                {{ getMobileLocationJobList.rates.service.default_qty }}
              </th>
              <td class="text-right">
                ${{ getMobileLocationJobList.rates.rate }}
              </td>
              <td
                class="text-right"
                v-if="getMobileLocationJobList.rates.service.is_drug_testing"
              >
                ${{
                  getMobileLocationJobList.rates.rate
                    | taxAmount(getMobileLocationJobList.rates.tax_percentage)
                }}
              </td>
              <td class="text-right" v-else>
                ${{
                  getMobileLocationJobList.rates.rate
                    | taxAmount(getMobileLocationJobList.rates.tax_percentage)
                }}
              </td>

              <!-- <td class="text-right">${{ getMobileLocationJobList | toFixedTwoDigitRate }}</td> -->
              <td
                class="text-right"
                v-if="getMobileLocationJobList.rates.service.is_drug_testing"
              >
                ${{ getTotalRateAmount.toFixed(2) }}
                <!-- ${{getItemTotalAmount}} -->
              </td>
              <td class="text-right" v-else>
                ${{ getTotalRateAmount.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 col-md-12 mb-5" v-if="getMobileLocationJobList.rates.service.service_type_code == 'fdle'">
        <table class="table table-bg-success" v-if="getServiceOriCode?.data.rate">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="text-right">
                1
              </td>
              <td class="text-right">
                ${{ getServiceOriCode.data.rate }}
              </td>
              <td
                class="text-right"
              >
              ${{ getServiceOriCode.data.tax_percentage }}
              </td>
              <td class="text-right" >

                  ${{ getTotalRateAmount.toFixed(2) }}
              </td>

              <!-- <td class="text-right">${{ getMobileLocationJobList | toFixedTwoDigitRate }}</td> -->
             
            </tr>
          </tbody>
        </table>
      </div>
        </div>
        <div class="col-12">
            <hr />
            <div class="col-12">
            <h5
              class="float-right"
           
            >
              Total: ${{ getTotalRateAmount.toFixed(2) }}
            </h5>
            </div>

        <div class="row" style="margin-top: 15px">

          <div
            class="col-md-8 col-lg-6 col-sm-10 col-12 abbre-code"
            v-if="
              this.getMobileLocationJobList.rates.service.service_type_code ==
                'fdle'
            "
          >
            <label
              >ORI :
              <strong
                class="text-uppercase"
                v-if="getServiceOriCode.success == true"
                >{{ getServiceOriCode?.data?.ori_number }}</strong
              >
            </label>
          </div>

          <div class="col-md-12" v-if="getServiceOriCode.success == true">
            <div class="row">
              <div class="col-md-5">
                <ul style="list-style: none; padding: 0">
                  <li>
                    Name :
                    <b>{{ getServiceOriCode.data.profession }}</b>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>  
      </div>

      <div
        class="col-md-6"
        v-if="
          getMobileLocationJobList.service_provider.payment_method ==
            'heartland' &&
            getMobileLocationJobList.job.payment_by == 'Individual'
        "
      >
        <div class="row">
          <div class="col-md-12">
            <h5>Payment Information</h5>
            <hr />
            <ul class="payment-type">
              <li>
                <div style="padding: 10px; background: #eef0f8">
                  <div class="hidden" name="mytoken" id="mytoken"></div>
                  <div class="hidden" name="expiremonth" id="expiremonth"></div>
                  <div class="hidden" name="expireyear" id="expireyear"></div>
                  <div class="hidden" name="card_type" id="card_type"></div>
                  <div class="hidden" name="last_four" id="last_four"></div>
                  <div class="hidden" name="token_type" id="token_type"></div>

                  <div class="form-wrapper">
                    <form id="iframes" action="" method="GET">
                      <div id="ss-banner"></div>

                      <div class="form-row">
                        <label for="iframesCardNumber">Card Number:</label>
                        <div id="iframesCardNumber"></div>
                      </div>

                      <div class="form-row">
                        <label for="iframesCardExpiration"
                          >Card Expiration:</label
                        >
                        <div id="iframesCardExpiration"></div>
                      </div>

                      <div class="form-row">
                        <label for="iframesCardCvv">Card CVV:</label>
                        <div id="iframesCardCvv"></div>
                      </div>
                      <br />
                    </form>
                    <div class="form-row">
                      <label for="iframesCardCvv">Address</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="Address"
                        :value="getPaymentCardHolderAddress"
                        @input="updateCardAddress"
                      />
                    </div>
                    <div class="form-row">
                      <label for="iframesCardCvv">Postal/Zip</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="Postal/Zip"
                        @input="updatePostalZip"
                        :value="getPaymentCardPostalZip"
                      />
                    </div>
                  </div>
                </div>
              </li>
              <div
                class="form-group mt-0 mb-0"
                v-if="getMobileLocationJobList.location.enable_on_site_payment"
                style="display: inline-flex"
              >
                <input
                  type="checkbox"
                  name="on_site_payment"
                  id="on_site_payment"
                  v-model="onSitePayment"
                  @change="updatePaymentType(1)"
                />
                <label
                  class="ml-2"
                  for="on_site_payment"
                  style="margin-top: 11px"
                  >Click here for on-site payment</label
                >
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="col-md-6"
        v-if="
          getMobileLocationJobList.service_provider.payment_method ==
            'cardpointe' &&
            getMobileLocationJobList.job.payment_by == 'Individual'
        "
      >
        <div class="row">
          <div class="col-md-12" id="loading-payment">
            <loading-spinner style="margin-top: 20px"> </loading-spinner>
          </div>
          <div class="col-md-12" id="payment-info">
            <h5>Payment Information</h5>
            <hr />
            <ul class="payment-type">
              <li>
                <label for="">
                  <img
                    src="@/assets/images/icon/png/card.png"
                    width="20%"
                    alt=""
                  />
                  <span>Card Payment</span></label
                >

                <div
                  style="padding: 10px; background: #eef0f8; margin-top: 10px"
                >
                  <div class="d-flex">
                    <label
                      for=""
                      style="
                        margin-top: 5px;
                        width: 60%;
                        float: left;
                        font-weight: 500;
                      "
                      >Supported Cards</label
                    >
                    <img
                      style="width: 40%; float: left"
                      src="@/assets/images/card/card_icon_v1_all.svg"
                      alt=""
                    />
                  </div>
                  <hr />
                  <div class="row">
                    <div class="form-group col-md-6 col-6 cd-field">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Card Number</label
                      >
                      <!-- <div id="myToken"></div> -->
                      <div class="hidden" name="mytoken" id="mytoken"></div>
                      <!-- <input type="hidden" name="cardtype" id="cardtype" /> -->
                      <form name="tokenform" id="tokenform">
                        <iframe
                          id="tokenframe"
                          name="tokenframe"
                          scrolling="no"
                          height="52"
                          width="200"
                          autofocus="true"
                          frameborder="0"
                        ></iframe>
                      </form>
                      <div id="cc_error" class="cd-err-row hidden">
                        Please enter a valid credit card number
                      </div>
                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_number']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_number"][0]
                        }}</span
                      >
                      <div
                        class="input-group-prepend"
                        v-if="getPaymentCardType != ''"
                      >
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style="padding: 0; background: white; border: none"
                        >
                          <img
                            :src="
                              require('@/assets/images/card/card_icon_v1_' +
                                getPaymentCardType +
                                '.svg')
                            "
                            width="85%"
                            height="30px"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Expiration date</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="MM/YY"
                        v-mask="'##/##'"
                        @input="updateCardExpiry"
                        :value="getPaymentCardExpiry"
                        :class="
                          this.getPaymentType == 'C' && errors['card_expiry']
                            ? 'border border-danger'
                            : ''
                        "
                      />

                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_expiry']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_expiry"][0]
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Cardholder Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Cardholder Name"
                        :value="getPaymentCardHolderName"
                        @input="updateCardHolderName"
                        :class="
                          this.getPaymentType == 'C' &&
                          errors['card_holder_name']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                      <span
                        v-if="
                          this.getPaymentType == 'C' &&
                            errors['card_holder_name']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_holder_name"][0]
                        }}</span
                      >
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 text-uppercase mb-1 paymemt-label"
                        >Security Code</label
                      >

                      <input
                        type="text"
                        class="form-control"
                        max="9999"
                        :placeholder="getPaymentCardProperty.cvv_placeholder"
                        v-mask="getPaymentCardProperty.cvv_mask"
                        @input="updateCardCvvNumber"
                        :value="getPaymentCardCvvNumber"
                        :class="
                          this.getPaymentType == 'C' && errors['card_cvv_no']
                            ? 'border border-danger'
                            : ''
                        "
                      />

                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_cvv_no']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_cvv_no"][0]
                        }}</span
                      >
                    </div>
                  </div>
                  <hr />
                  <div
                    style="
                      padding: 0 0 1%;
                      background: #eef0f8;
                      margin-top: 10px;
                    "
                  >
                    <div class="d-flex">
                      <label
                        for=""
                        style="width: 60%; float: left; font-weight: 500"
                        >Billing Address</label
                      >
                    </div>
                    <span style="opacity: 0.5"
                      >As it appears on credit card statement.</span
                    >
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Address</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address"
                        :value="getPaymentCardHolderAddress"
                        @input="updateCardAddress"
                      />
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_address']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_address"][0]
                        }}</span
                      > -->
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >City</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        :value="getPaymentCardHolderCity"
                        @input="updateCardcity"
                      />
                      <!-- <span
                        v-if="this.getPaymentType == 'C' && errors['card_city']"
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_city"][0]
                        }}</span
                      > -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Country</label
                      >
                      <select class="form-control" @change="updateCountry">
                        <option value="" hidden>Select Country</option>
                        <option
                          v-for="(item, index) in getCountries"
                          :key="index"
                          :value="item.country_code"
                          :selected="
                            getPaymentCardHolderCountry == item.country_code
                          "
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_country']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_country"][0]
                        }}</span
                      > -->
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >State</label
                      >
                      <select
                        class="form-control"
                        @change="updateState"
                        v-if="getPaymentCardHolderCountry == 'US'"
                      >
                        <option value="">Select State</option>
                        <option
                          v-for="(item, index) in getStates"
                          :key="index"
                          :value="item.name"
                          :selected="getPaymentCardHolderState == item.name"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        placeholder="State"
                        :value="getPaymentCardHolderState"
                        @input="updateState"
                      />
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_state']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_state"][0]
                        }}</span
                      > -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Postal/Zip</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Postal/Zip"
                        @input="updatePostalZip"
                        :value="getPaymentCardPostalZip"
                      />
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Phone</label
                      >
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          @click="toggleCountriesPhoneCode(true)"
                          style="cursor: pointer; border-right: 1px solid"
                        >
                          <span
                            class="input-group-text"
                            id="basic-addon1"
                            style="
                              min-width: 80px;
                              max-width: 80px;
                              text-align: center;
                            "
                          >
                            <img
                              :src="getPaymentCardPhonecode.flag"
                              alt=""
                              class="mr-1"
                            />
                            <b style="width: 100%; text-align: center">{{
                              getPaymentCardPhonecode.dial_code
                            }}</b>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          :value="getPaymentCardPhone"
                          @input="updatePaymentCardPhone"
                          v-mask="'(###)-###-####'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <div
                class="form-group mt-0 mb-0"
                style="display: inline-flex"
                v-if="getMobileLocationJobList.location.enable_on_site_payment"
              >
                <input
                  type="checkbox"
                  name="on_site_payment"
                  id="on_site_payment"
                  v-model="onSitePayment"
                  @change="updatePaymentType(1)"
                />
                <label
                  class="ml-2"
                  for="on_site_payment"
                  style="margin-top: 11px"
                  >Click here for on-site payment</label
                >
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import Title from '../../components/frontend/title.vue';
/*  eslint-disable */
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
import Heartland from "@/assets/js/components/Heartland.js";
window.Heartland = Heartland;
import loadingSpinner from "@/components/frontend/spinner.vue";
import { load } from "recaptcha-v3";
import { RECAPTCHATOKENVERIFY } from "@/core/services/store/actions.type";
import {
  SET_RATE_TOTAL_AMOUNT,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_CITY,
  CARD_COUNTRY,
  CARD_STATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  RECAPTCHATOKEN,
} from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
export default {
  components: {
    "v-countries-modal": vCountriesForPhoneCode,
    loadingSpinner,
  },
  data() {
    return {
      hps: "",
      onSitePayment: false,
      showCountriesWithPhoneCode: false,
      id_scroll_list_country: "",
      errors: [],
    };
  },
  computed: {
    ...mapGetters([
      "getMobileLocationJobList",
      "getTotalRateAmount",
      "getPaymentCardHolderAddress",
      "getPaymentCardPostalZip",
      "getPaymentCardPhone",
      "getPaymentCardPhonecode",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderCountry",
      "getPaymentType",
      "getPaymentCardType",
      "getPaymentCardExpiry",
      "getPaymentCardHolderName",
      "getPaymentCardProperty",
      "getPaymentCardCvvNumber",
      "getPaymentCardHolderCity",
      "getCountries",
      "getStates",
      "getServiceOriCode",
      "getPaymentCardHolderState",
    ]),
    getTotalRateAmount() {
      if (this.getMobileLocationJobList.rates.service.service_type_code != 'fdle') {
      var rate =
        parseFloat(this.getMobileLocationJobList.rates.rate) +
        (parseFloat(this.getMobileLocationJobList.rates.rate) *
          parseFloat(this.getMobileLocationJobList.rates.tax_percentage)) /
        100;
        
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, rate);
      return rate;
    } else {
      var rate_ori = parseFloat(parseFloat(this.getServiceOriCode.data.tax_percentage) + parseFloat(this.getServiceOriCode.data.rate));
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, rate_ori);
      return rate_ori;
    }
    },
  },

  mounted() {
    /* eslint-disable */
    if (this.getMobileLocationJobList.job.payment_by == "Company") {
      this.$store.commit(UPDATE_PAYMENT_TYPE, "CP");
    } else {
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
    }
    let payment_method =
      this.getMobileLocationJobList.service_provider.payment_method ==
      "heartland"
        ? true
        : false;

    if (payment_method) {
      this.hps = new Heartland.HPS({
        publicKey: this.getMobileLocationJobList.service_provider.public_key,
        type: "iframe",
        // Configure the iframe fields to tell the library where
        // the iframe should be inserted into the DOM and some
        // basic options
        fields: {
          cardNumber: {
            target: "iframesCardNumber",
            placeholder: "•••• •••• •••• ••••",
          },
          cardExpiration: {
            target: "iframesCardExpiration",
            placeholder: "MM / YYYY",
          },
          cardCvv: {
            target: "iframesCardCvv",
            placeholder: "CVV",
          },
          // submit: {
          //   value: "Pay $10.00",
          //   target: "iframesSubmit"
          // }
        },
        // Collection of CSS to inject into the iframes.
        // These properties can match the site's styles
        // to create a seamless experience.
        style: {
          input: {
            background: "#fff",
            border: "1px solid",
            "border-color": "#bbb3b9 #c7c1c6 #c7c1c6",
            "box-sizing": "border-box",
            "font-family": "serif",
            "font-size": "16px",
            "line-height": "1",
            margin: "0 .5em 0 0",
            "max-width": "100%",
            outline: "0",
            padding: "0.5278em",
            "vertical-align": "baseline",
            height: "50px",
            width: "100% !important",
          },
          "#heartland-field": {
            "font-family": "sans-serif",
            "box-sizing": "border-box",
            display: "block",
            height: "50px",
            padding: "6px 12px",
            "font-size": "14px",
            "line-height": "1.42857143",
            color: "#555",
            "background-color": "#fff",
            border: "1px solid #ccc",
            "border-radius": "0px",
            "-webkit-box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
            "box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
            "-webkit-transition":
              "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
            "-o-transition":
              "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
            transition:
              "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
            width: "100%",
          },
          "#heartland-field[name=submit]": {
            "background-color": "#36b46e",
            "font-family": "sans-serif",
            "text-transform": "uppercase",
            color: "#ffffff",
            border: "0px solid transparent",
            // display: "none"
          },
          "#heartland-field[name=submit]:focus": {
            color: "#ffffff",
            "background-color": "#258851",
            outline: "none",
          },
          "#heartland-field[name=submit]:hover": {
            "background-color": "#258851",
          },
          "#heartland-field-wrapper #heartland-field:focus": {
            border: "1px solid #3989e3",
            outline: "none",
            "box-shadow": "none",
            height: "50px",
          },
          "heartland-field-wrapper #heartland-field": {
            height: "50px",
          },
          "input[type=submit]": {
            "box-sizing": "border-box",
            display: "inline-block",
            padding: "6px 12px",
            "margin-bottom": "0",
            "font-size": "14px",
            "font-weight": "400",
            "line-height": "1.42857143",
            "text-align": "center",
            "white-space": "nowrap",
            "vertical-align": "middle",
            "-ms-touch-action": "manipulation",
            "touch-action": "manipulation",
            cursor: "pointer",
            "-webkit-user-select": "none",
            "-moz-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none",
            "background-image": "none",
            border: "1px solid transparent",
            "border-radius": "4px",
            color: "#fff",
            "background-color": "#337ab7",
            "border-color": "#2e6da4",
          },
          "#heartland-field[placeholder]": {
            "letter-spacing": "3px",
          },
          "#heartland-field[name=cardCvv]": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right",
            "background-size": "63px 40px",
          },
          "input#heartland-field[name=cardNumber]": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right",
            "background-size": "55px 35px",
          },
          "#heartland-field.invalid.card-type-visa": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right",
            "background-size": "83px 88px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-visa": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top",
            "background-size": "82px 86px",
          },
          "#heartland-field.invalid.card-type-discover": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
            "background-size": "85px 90px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-discover": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
            "background-size": "85px 90px",
            "background-position-y": "1px",
          },
          "#heartland-field.invalid.card-type-amex": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right",
            "background-size": "50px 90px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-amex": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top",
            "background-size": "50px 90px",
          },
          "#heartland-field.invalid.card-type-mastercard": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
            "background-size": "62px 105px",
            "background-position-y": "-52px",
          },
          "#heartland-field.valid.card-type-mastercard": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
            "background-size": "62px 105px",
            "background-position-y": "-1px",
          },
          "#heartland-field.invalid.card-type-jcb": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right",
            "background-size": "55px 94px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-jcb": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top",
            "background-size": "55px 94px",
            "background-position-y": "2px",
          },
          "input#heartland-field[name=cardNumber]::-ms-clear": {
            display: "none",
          },
        },
        onTokenSuccess: function (resp) {
          // console.log(resp)
          document.getElementById("mytoken").innerHTML = resp.token_value;
          document.getElementById("expiremonth").innerHTML = resp.exp_month;
          document.getElementById("expireyear").innerHTML = resp.exp_year;
          document.getElementById("token_type").innerHTML = resp.token_type;
          document.getElementById("last_four").innerHTML = resp.last_four;
          document.getElementById("card_type").innerHTML = resp.card_type;
        },
        onTokenError: function () {},
        onEvent: function () {},
      });
    }
    /* eslint-enable */
    if (!payment_method) {
      var frame = document.getElementById("tokenframe");
      frame.addEventListener("load", () => {
        setTimeout(function() {
          document.getElementById("payment-info").style.display = "block";
          document.getElementById("loading-payment").style.display = "none";
        }, 10);
      });
      var mytoken = document.getElementById("mytoken");
      mytoken.innerHTML = "";
      // document.getElementById("myToken").style.display = "none";
      window.addEventListener(
        "message",
        function(event) {
          var token;
          mytoken.innerHTML = "";

          token = JSON.parse(event.data);

          if (token.validationError != undefined) {
            document.getElementById("tokenframe").focus();
            var error = document.getElementById("cc_error");
            error.style.display = "block";
            mytoken.innerHTML = "";

            setTimeout(function() {
              error.style.display = "none";
            }, 2000);
            return false;
          }
          mytoken.innerHTML = token.message;
          // document.getElementById("myToken").fadeIn();
        },
        false
      );
      this.setFrame();
    }
    // this.recaptchafun();

    // let add_rate = parseFloat(this.getfClientRate.rate);
    // this.$store.commit(SET_RATE_TOTAL_AMOUNT, add_rate.toFixed(2));
  },

  filters: {
    taxAmount(amount, taxPer) {
      amount = parseFloat(amount);
      taxPer = parseFloat(taxPer);
      return ((amount * taxPer) / 100).toFixed(2);
    }
  },
  methods: {
    async recaptchafun() {
      const recaptcha = await load(this.sitekey, {
        autoHideBadge: true
      });
      const token = await recaptcha.execute("pay");

      // console.log(token); // Will also print the token
      this.$store.dispatch(RECAPTCHATOKENVERIFY, token).then(res => {
        let data = JSON.parse(res);
        if (data.success) {
          this.$store.commit(RECAPTCHATOKEN, token);
        }
      });
    },
    updateCardcity(e) {
      delete this.errors["card_city"];
      var value = e.target.value;
      // alert(value);
      this.$store.commit(SET_PAYMENT_CARD_CITY, value);
      if (value.length < 1) {
        this.errors.card_city = {
          0: "City cannot be empty"
        };
      }
    },
    updateCountry(e) {
      this.$store.commit(CARD_COUNTRY, e.target.value);
      this.$store.commit(CARD_STATE, "");
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_city = {
          0: "Country cannot be empty"
        };
      }
    },
    updateState(e) {
      this.$store.commit(CARD_STATE, e.target.value);
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_city = {
          0: "State cannot be empty"
        };
      }
    },
    updateCardCvvNumber(e) {
      delete this.errors["card_cvv_no"];
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_cvv_no = {
          0: "Security Code cannot be empty"
        };
      }
      // alert(value);
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, value);
    },
    updateCardHolderName(e) {
      delete this.errors["card_holder_name"];
      var value = e.target.value;
      // alert(value);
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, value);
      if (value.length < 1) {
        this.errors.card_holder_name = {
          0: "Card holder name cannot be empty"
        };
      }
    },
    updateCardExpiry(e) {
      delete this.errors["card_expiry"];
      // this.errors.card_expiry = null;

      var value = e.target.value;
      // alert(value);
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, value);
      if (value.length < 1) {
        this.errors.card_expiry = {
          0: "Expiration date cannot be empty"
        };
      }
      if (value.length == 5) {
        var today = new Date();
        var someday = new Date();
        someday.setFullYear(
          today
            .getFullYear()
            .toString()
            .substr(0, 2) + value.split("/")[1],
          value.split("/")[0],
          1
        );

        if (someday < today) {
          this.errors.card_expiry = {
            0: "Card Expiration Date is not validated"
          };
        }
      }
    },
    toggleCountriesPhoneCode(flag) {
      if (flag) {
        this.id_scroll_list_country =
          "scroll_li" + this.getPaymentCardPhonecode.code;
      }
      this.showCountriesWithPhoneCode = flag;
      this.$refs.email.$el.focus();
    },
    updatePaymentCardPhone(e) {
      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, value);
    },
    updateCardAddress(e) {
      delete this.errors["card_address"];
      var value = e.target.value;
      // alert(value);
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, value);
      if (value.length < 1) {
        this.errors.card_address = {
          0: "Card Address cannot be empty"
        };
      }
    },
    updatePostalZip(e) {
      var value = e.target.value;

      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, value);
    },
    setFrame: function() {
      let url = this.getMobileLocationJobList.service_provider.token_url;
      document.getElementById("tokenframe").src = url;
    },
    updatePaymentType(value) {
      if (this.onSitePayment) {
        value = "O";
        this.errors = [];
        this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
        this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
        this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
        this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
        this.$store.commit(SET_PAYMENT_CARD_CITY, "");
        this.$store.commit(CARD_COUNTRY, "");
        this.$store.commit(CARD_STATE, "");
      } else {
        value = "C";
      }
      this.$store.commit(UPDATE_PAYMENT_TYPE, value);
    }
  }
};
</script>
