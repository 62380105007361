<style lang="scss">
#confirm-form {
  position: relative;
  // height: calc(100vh - 215px);
  .form-control {
    height: 40px;
  }
  .alert-dotted {
    border: 2px dotted;
  }
  .confirm-msg {
    // position: absolute;
    margin-bottom: 150px;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
}
</style>
<template>
  <section id="confirm-form">
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-5">Confirm Details</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-unstyled">
          <li>
            <label for="">First Name:</label>
            <label for="" class="detail">{{ getApplicantFirstName }}</label>
          </li>
          <li v-if="getApplicantMiddleName != ''">
            <label for="">Middle Name: </label>
            <label for="" class="detail">{{ getApplicantMiddleName }}</label>
          </li>

          <li>
            <label for="">Last Name: </label>
            <label for="" class="detail">{{ getApplicantlastName }}</label>
          </li>
          <li>
            <label for="">Social Security No.:</label>
            <label for="" class="detail">{{
              getApplicantSSN != "" ? getApplicantSSN : "None"
            }}</label>
          </li>
          <li>
            <label for="">Date of Birth: </label>
            <label for="" class="detail">{{
              getApplicantDateOfBirth | dateOfBirthFormat
            }}</label>
          </li>
          <li class="mt-5" style="display:inline-flex">
            <input
              type="checkbox"
              v-model="sms_status"
              @change="updateSmsStstus"
              name=""
              id="sms_status"
            />
            <label
              for="sms_status"
              class="ml-2"
              style="font-size:16px;font-weight:600;margin-top:8px"
              >I would like to confirm the details related to my
              appointment</label
            >
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
// import Title from '../../components/frontend/title.vue';
// import moment from "moment";
import { SET_SMS_STATUS } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters([
      "getApplicantFirstName",
      "getApplicantlastName",
      "getApplicantSSN",
      "getApplicantDateOfBirth",
      "getSettingNote",
      "getSmsStatus",
      "getApplicantMiddleName"
    ])
  },
  data() {
    return {
      sms_status: false
    };
  },
  created() {
    this.sms_status = this.getSmsStatus;
  },
  methods: {
    updateSmsStstus() {
      // alert(this.sms_status);
      // alert(e.target.value);
      this.$store.commit(SET_SMS_STATUS, this.sms_status);
      this.sms_status = this.getSmsStatus;
    }
  },
  filters: {
    dateOfBirthFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    }
  }
};
</script>
