<style lang="scss">
#contact-form {
  .form-control {
    height: 40px;
  }
  .drug-testing {
    margin-bottom: 2%;
  }
  .card-drug-testing {
    background-color: #d6d8d9 !important;
  }
  .hide {
    display: none;
  }
}
</style>
<template>
  <section id="contact-form">
    <v-countries-modal
      :li-scrolls="id_scroll_list_country"
      :show-modal="showCountriesWithPhoneCode"
      v-on:closemodal="toggleCountriesPhoneCode"
    ></v-countries-modal>
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-5">Contact Information</h5>
        <p>Please enter all the required field *</p>

        <div
          class="row drug-testing"
          v-for="(applicant, i) in getMobileAppointApplicants"
          :key="i"
        >
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >First Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              @input="resetErrorMsg('fname')"
              class="form-control fname_"
              v-model="applicant.applicant.fname"
              :class="errorMsg['fname'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['fname']" class="text-danger fname">{{
              errorMsg["fname"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1">Middle Name</label>
            <input
              type="text"
              class="form-control"
              v-model="applicant.applicant.mname"
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Last Name<span class="text-danger">*</span></label
            >
            <input
              type="text"
              @input="resetErrorMsg('lname')"
              class="form-control lname_"
              v-model="applicant.applicant.lname"
              :class="errorMsg['lname'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['lname']" class="text-danger lname">{{
              errorMsg["lname"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 form-group mb-3 pr-5">
            <label for="exampleInputEmail1"
              >Date of Birth <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-4 dob_res pr-0">
                <select
                  @change="resetErrorMsg('dob_m')"
                  class="form-select form-control dob_m_"
                  v-model="applicant.applicant.dob_m"
                  :class="errorMsg['dob_m'] ? 'border border-danger' : ''"
                >
                  <option value="">Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <span v-if="errorMsg['dob_m']" class="text-danger dob_m">{{
                  errorMsg["dob_m"][0]
                }}</span>
              </div>
              <div class="col-4 dob_res pl-2 pr-0">
                <select
                  @change="resetErrorMsg('dob_d')"
                  class="form-select form-control dob_d_"
                  v-model="applicant.applicant.dob_d"
                  :class="errorMsg['dob_d'] ? 'border border-danger' : ''"
                >
                  <option value="">Day</option>
                  <option v-for="day in days" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select>
                <span v-if="errorMsg['dob_d']" class="text-danger dob_d">{{
                  errorMsg["dob_d"][0]
                }}</span>
              </div>
              <div class="col-4 dob_res pl-2 pr-0">
                <select
                  @change="resetErrorMsg('dob_y')"
                  class="form-select form-control dob_y_"
                  v-model="applicant.applicant.dob_y"
                  :class="errorMsg['dob_y'] ? 'border border-danger' : ''"
                >
                  <option value="">Year</option>
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
                <span v-if="errorMsg['dob_y']" class="text-danger dob_y">{{
                  errorMsg["dob_y"][0]
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Email<span class="text-danger">*</span></label
            >
            <input
              type="email"
              @input="resetErrorMsg('email')"
              class="form-control email_"
              v-model="applicant.applicant.email"
              :class="errorMsg['email'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['email']" class="text-danger email">{{
              errorMsg["email"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Confirm Email<span class="text-danger">*</span></label
            >
            <input
              type="email"
              @input="resetErrorMsg('cemail')"
              class="form-control cemail_"
              v-model="applicant.applicant.cemail"
              :class="errorMsg['cemail'] ? 'border border-danger' : ''"
              @click.right.prevent
              @paste.prevent
            />
            <span
              v-if="
                applicant.applicant.cemail != applicant.applicant.email &&
                  applicant.applicant.cemail != ''
              "
              class="text-danger"
              >Confirm email doesn't matched with email</span
            >
            <br />
            <span v-if="errorMsg['cemail']" class="text-danger cemail">{{
              errorMsg["cemail"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Phone No.<span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div
                class="input-group-prepend"
                @click="toggleCountriesPhoneCode(true)"
                style="cursor: pointer; border-right: 1px solid"
              >
                <span
                  class="input-group-text"
                  id="basic-addon1"
                  style="min-width: 80px; max-width: 80px; text-align: center"
                >
                  <img
                    :src="getSelectedCountryPhoneCode.flag"
                    alt=""
                    class="mr-1"
                  />
                  <b style="width: 100%; text-align: center">{{
                    getSelectedCountryPhoneCode.dial_code
                  }}</b>
                </span>
              </div>
              <input
                type="text"
                @input="resetErrorMsg('phone_no')"
                class="form-control phone_no_"
                v-model="applicant.applicant.phone_no"
                v-mask="'(###)-###-####'"
                :class="errorMsg['phone_no'] ? 'border border-danger' : ''"
              />
            </div>
            <span v-if="errorMsg['phone_no']" class="text-danger phone_no">{{
              errorMsg["phone_no"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Address Line 1<span class="text-danger">*</span></label
            >
            <input
              type="text"
              @input="resetErrorMsg('address1')"
              class="form-control address1_"
              v-model="applicant.applicant.address1"
              :class="errorMsg['address1'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['address1']" class="text-danger address1">{{
              errorMsg["address1"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1">Address Line 2</label>
            <input
              type="text"
              class="form-control"
              v-model="applicant.applicant.address2"
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >City <span class="text-danger">*</span></label
            >
            <!-- //updateCity  getApplicantCity -->
            <input
              type="text"
              @input="resetErrorMsg('city')"
              class="form-control city_"
              v-model="applicant.applicant.city"
              :class="errorMsg['city'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['city']" class="text-danger city">{{
              errorMsg["city"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-3 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Zip Code<span class="text-danger">*</span></label
            >
            <input
              type="text"
              @input="resetErrorMsg('zipcode')"
              class="form-control zipcode_"
              v-model="applicant.applicant.zipcode"
              v-mask="'#####'"
              :class="errorMsg['zipcode'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['zipcode']" class="text-danger zipcode">{{
              errorMsg["zipcode"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-3 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >State <span class="text-danger">*</span></label
            >
            <select
              @change="resetErrorMsg('state')"
              class="form-select form-control state_"
              v-model="applicant.applicant.state"
              :class="errorMsg['state'] ? 'border border-danger' : ''"
            >
              <option value="">Select State</option>
              <option
                v-for="(item, index) in getStates"
                :key="index"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
            <span v-if="errorMsg['state']" class="text-danger state">{{
              errorMsg["state"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-3 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1">Social Security No.</label>
            <input
              type="text"
              class="form-control"
              v-model="applicant.applicant.ssn"
              v-mask="'###-##-####'"
              @input="updateSSN"
            />
          </div>

          <div class="col-lg-4 col-md-5 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Reason for taking this service
              <span class="text-danger">*</span></label
            >
            <select
              @change="resetErrorMsg('reason')"
              class="form-select form-control reason_"
              v-model="applicant.applicant.reason"
              :class="errorMsg['reason'] ? 'border border-danger' : ''"
            >
              <option value="">Choose an Option</option>
              <option value="Probation">Probation</option>
              <option value="Pre-employment">Pre-employment</option>
              <option value="Personal">Personal</option>
              <option value="Court Ordered">Court Ordered</option>
              <option value="Other">Other</option>
              <option value="Random">Random</option>
              <option value="Post Accident">Post Accident</option>
              <option value="Reasonable Suspicion">Reasonable Suspicion</option>
              <option value="Return to Duty">Return to Duty</option>
              <option value="Follow Up">Follow Up</option>
              <option value="Fit for Duty">Fit for Duty</option>
            </select>
            <span v-if="errorMsg['reason']" class="text-danger reason">{{
              errorMsg["reason"][0]
            }}</span>
          </div>
          <div
            class="col-lg-4 col-md-5 col-sm-12 form-group mb-2"
            v-if="
              applicant.applicant.reason == 'Probation' ||
                applicant.applicant.reason == 'Personal' ||
                applicant.applicant.reason == 'Other' ||
                applicant.applicant.reason == 'Court Ordered'
            "
          >
            <label for="exampleInputEmail1"
              >Direct Observed Required?
              <span class="text-danger">*</span></label
            >
            <select
              class="form-select form-control"
              v-model="applicant.applicant.direct_obs_req"
              @change="setApplicants"
            >
              <option value="1">Yes</option>
              <option value="0" selected>No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { FETCH_STATES } from "@/core/services/store/actions.type";
import {
  UPDATE_REASONFORFINGERPRINT,
  UPDATE_ZIPCODE,
  UPDATE_ADDRESSLINE2,
  UPDATE_ADDRESSLINE1,
  UPDATE_PHONENO,
  UPDATE_CONFIRMEMAIL,
  UPDATE_EMAIL,
  UPDATE_LASTNAME,
  UPDATE_MIDDLENAME,
  UPDATE_FIRSTNAME,
  UPDATE_STATE,
  UPDATE_CITY,
  UPDATE_SOCIAL_SECURITY,
  SET_APPICANTS,
  UPDATE_DATEOFBIRTH
} from "@/core/services/store/mutations.type";
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    "v-countries-modal": vCountriesForPhoneCode
  },
  computed: {
    years() {
      const year = new Date().getFullYear() + 1;
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1900 + index
      );
    },
    dob() {
      return (
        this.minTwoDigits(this.getMobileAppointApplicants[0].applicant.dob_m) +
        "/" +
        this.minTwoDigits(this.getMobileAppointApplicants[0].applicant.dob_d) +
        "/" +
        this.getMobileAppointApplicants[0].applicant.dob_y
      );
    },
    days() {
      const day = 33;
      return Array.from({ length: day - 1 }, (value, index) => 1 + index);
    },
    ...mapGetters([
      "getCities",
      "getStates",
      "getApplicantFirstName",
      "getApplicantMiddleName",
      "getApplicantlastName",
      "getApplicantEmail",
      "getApplicantConfirmEmail",
      "getApplicantPhoneNo",
      "getApplicantAddressLine1",
      "getApplicantAddressLine2",
      "getApplicantZipCode",
      "getApplicantReason",
      "getApplicantCity",
      "getApplicantState",
      "getSelectedCountryPhoneCode",
      "getMobileAppointApplicants"
    ])
  },
  data() {
    return {
      id_scroll_list_country: "",
      showCountriesWithPhoneCode: false,
      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      applicants: []
    };
  },
  created() {
    // this.$emit("startclock", 1);
    // this.$store.dispatch(FETCH_CITIES);
    this.$store.dispatch(FETCH_STATES);
    this.applicants = this.getMobileAppointApplicants;
  },
  props: {
    errorMsg: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toggleCountriesPhoneCode(flag) {
      if (flag) {
        this.id_scroll_list_country =
          "scroll_li" + this.getSelectedCountryPhoneCode.code;
      }
      this.showCountriesWithPhoneCode = flag;
    },
    minTwoDigits(n) {
      return (n < 10 ? "0" : "") + n;
    },
    resetErrorMsg(val) {
      if (val == "dob_m" || val == "dob_y" || val == "dob_d") {
        this.$store.commit(UPDATE_DATEOFBIRTH, this.dob);
      }
      if (val == "fname") {
        this.$store.commit(
          UPDATE_FIRSTNAME,
          this.getMobileAppointApplicants[0].applicant.fname
        );
      }
      if (val == "lname") {
        this.$store.commit(
          UPDATE_LASTNAME,
          this.getMobileAppointApplicants[0].applicant.lname
        );
      }
      if (val == "ssn") {
        this.$store.commit(
          UPDATE_SOCIAL_SECURITY,
          this.getMobileAppointApplicants[0].applicant.ssn
        );
      }
      document.getElementsByClassName(val)[0].classList.add("hide");
      document
        .getElementsByClassName(val + "_")[0]
        .classList.remove("border-danger");
    },
    setApplicants() {
      this.$store.commit(SET_APPICANTS, this.applicants);
    },
    updateFirstName(e) {
      this.$store.commit(UPDATE_FIRSTNAME, e.target.value);
    },
    updateSSN(e) {
      this.$store.commit(UPDATE_SOCIAL_SECURITY, e.target.value);
    },
    updateMiddleName(e) {
      this.$store.commit(UPDATE_MIDDLENAME, e.target.value);
    },
    updateLastName(e) {
      this.$store.commit(UPDATE_LASTNAME, e.target.value);
    },
    updateEmail(e) {
      this.validateEmail(e.target.value);
      this.$store.commit(UPDATE_EMAIL, e.target.value);
    },
    updateConfirmEmail(e) {
      this.$store.commit(UPDATE_CONFIRMEMAIL, e.target.value);
    },
    updatePhoneNo(e) {
      this.$store.commit(UPDATE_PHONENO, e.target.value);
    },
    updateAddresLine1(e) {
      this.$store.commit(UPDATE_ADDRESSLINE1, e.target.value);
    },
    updateAddresLine2(e) {
      this.$store.commit(UPDATE_ADDRESSLINE2, e.target.value);
    },
    updateZipCode(e) {
      this.$store.commit(UPDATE_ZIPCODE, e.target.value);
    },
    updateReascon(e) {
      this.$store.commit(UPDATE_REASONFORFINGERPRINT, e.target.value);
    },
    updateState(e) {
      this.$store.commit(UPDATE_STATE, e.target.value);
    },
    updateCity(e) {
      this.$store.commit(UPDATE_CITY, e.target.value);
    }
  }
};
</script>
