<style lang="scss">
.alert-dotted {
  border: 2px dotted;
}
.confirm-msg {
  // position: absolute;
  margin-bottom: 150px;
}
.wiz_step {
  // margin-top: -10px;
  display: inline-flex;
  list-style: none;
  width: 100%;
  // background: #e3e3e3;
  height: 5px;
  .wiz-sub_step {
    margin-right: 0.5rem;
    width: calc(80% / 6);
    background: #e3e3e3;
    border-radius: 10px;
    height: 5px;
    &.current {
      background: #1bc5bd;
    }
    &.active {
      background: #a5dbd8;
    }
  }
}
.alert-danger-dim {
  background: #f7cece90;
}
.alert-success-dim {
  background-color: #b2facb70;
  border: 0;

  color: black;
}
#f-book-now {
  .alert-danger-dim {
    background: #f7cece90;
  }
  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
}
</style>
<template>
  <section class="content d-flex flex-column flex-column-fluid" id="f-book-now">
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div
      class="d-flex flex-column-fluid"
      v-if="getMobileLocationJobList != null"
    >
      <div class="container-fluid mb-25">
        <div class="row mb-3">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <v-title
              :title="getMobileLocationJobList.job.client_name"
              :has-icon="true"
              :icon="'fa fa-map-marker'"
            ></v-title>
            <v-title
              :title="getMobileLocationJobList.rates.service.name"
              :has-icon="true"
              :icon="'fa fa-cogs'"
            ></v-title>
            <!-- <div
              v-if="isRunning"
              style="
                float: right;
                margin-top: -3%;
                font-weight: 500;
                font-size: 15px !important;
              "
            >
              Time Left: {{ prettyTime }}
            </div> -->

            <ul class="wiz_step">
              <li
                style="cursor: pointer"
                class="wiz-sub_step"
                v-for="(witem, index) in wizardStep"
                :key="index"
                :class="
                  witem.active_status
                    ? 'active'
                    : witem.current_status
                    ? 'current'
                    : ''
                "
              >
                <!-- {{ witem.title }} -->
              </li>
            </ul>
          </div>
        </div>
        <!-- <canvas
          id="barcode"
          style="
            width: inherit;
            height: 75px;
            padding: 0 10px 0 0;
            display: none;
          "
        ></canvas> -->
        <!-- slot -->
        <!-- booknow form where step =0 -->
        <div class="row">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <div
              v-if="message != '' && message "
              role="alert"
              class="
                alert alert-custom alert-danger-dim alert-shadow
                fade
                show
                gutter-b
                alert-fixed
              "
            >
              <div class="alert-text" v-html="message"></div>
            </div>
            <b-modal
              id="otp"
              no-close-on-backdrop
              centered
              size="lg"
              v-model="openOtpmodal"
              :title="
                !getsmsverification ? 'Verify Phone OTP' : 'Verify Email OTP'
              "
            >
              <div class="row">
                <div class="col-6" v-if="!getsmsverification">
                  <img
                    src="@/assets/images/otp/phoneotp.jpg"
                    height="300px"
                    width="300px"
                  />
                </div>

                <div
                  class="col-6"
                  style="padding: 10px"
                  v-if="!getsmsverification"
                >
                  <div
                    class="col-12"
                    style="padding: 10px;font-weight: 700;"
                    v-if="!getsmsverification"
                  >
                    Please verify with the code sent to your contact number
                  </div>
                  <div class="form-group col-6">
                    <label for="">OTP <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      id=""
                      v-mask="'######'"
                      v-model="otp.otp_phone"
                      required
                    />
                    <span v-if="otp.is_phone" class="text-danger"
                      >OTP required</span
                    >
                  </div>
                  <div class="form-group col-12 mt-5">
                    Didn't recieve the code??
                    <button
                      class="btn btn-sm btn-info"
                      @click="ResendPhoneOtp()"
                      type="button"
                    >
                      Resend
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-6"
                  v-if="!getemailverification && getsmsverification"
                >
                  <img
                    src="@/assets/images/otp/emailotp.jpg"
                    height="300px"
                    width="300px"
                  />
                </div>
                <div
                  class="col-6"
                  style="padding: 10px"
                  v-if="!getemailverification && getsmsverification"
                >
                  <div
                    class="col-12"
                    style="padding: 10px; font-weight: 700;"
                    v-if="!getemailverification && getsmsverification"
                  >
                    Please verify with the code sent to your email
                  </div>
                  <div class="form-group col-6">
                    <label for="">OTP <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      id=""
                      v-model="otp.otp_email"
                      v-mask="'######'"
                    />
                    <span v-if="otp.is_email" class="text-danger"
                      >OTP required</span
                    >
                  </div>
                  <div class="form-group col-12 mt-5">
                    Didn't recieve the code??
                    <button
                      class="btn btn-sm btn-info"
                      @click="ResendEmailOtp()"
                      type="button"
                    >
                      Resend
                    </button>
                  </div>
                </div>
              </div>

              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    @click="otpverify()"
                    v-if="getsmsverification == false"
                    variant="success"
                    size="sm"
                    class="float-right ml-5"
                  >
                    Verify
                  </b-button>
                  <b-button
                    v-if="
                      getemailverification == false &&
                        getsmsverification == true
                    "
                    @click="otpemailverify()"
                    variant="success"
                    size="sm"
                    class="float-right ml-5"
                  >
                    Verify
                  </b-button>
                </div>
              </template>
            </b-modal>
            <!-- <select data-v-00b2af9c="" name="" id="" class="form-control">
              <option data-v-00b2af9c="" value="none">None</option>
              <option data-v-00b2af9c="" value="citizen">Enable Citizen</option>
              <option data-v-00b2af9c="" value="DISCLOSURE">Disclosure</option>
            </select> -->

            <citizen-comp
              v-if="
                getMobileLocationJobList.rates.service.service_type ==
                  'citizen' && checkStep('Citizenship Status')
              "
            ></citizen-comp>
            <disclosure-comp
              :check1="disclosureFlag"
              v-on:term="tiggerTerm"
              v-if="
                getMobileLocationJobList.rates.service.service_type ==
                  'citizen' && checkStep('APPLICANT RIGHT AND DISCLAIMERS')
              "
            ></disclosure-comp>
            <fn-disclosure-comp
              v-if="
                getMobileLocationJobList.rates.service.service_type ==
                  'DISCLOSURE' && checkStep('DISCLOSURE')
              "
              v-on:term="tiggerTerm"
              :check1="disclosureFlag"
            ></fn-disclosure-comp>
            <booknow-comp v-if="checkStep('Booknow')"></booknow-comp>
            <drug-form-comp
              v-if="
                getMobileLocationJobList.rates.service.is_drug_testing &&
                  checkStep('Contact Information_2')
              "
            ></drug-form-comp>
            <form-comp
              v-if="
                !getMobileLocationJobList.rates.service.is_drug_testing &&
                  checkStep('Contact Information')
              "
              :errorMsg="errorMsg"
            ></form-comp>
            <personal-information-comp
              v-if="
                !getMobileLocationJobList.rates.service.is_drug_testing &&
                  checkStep('Personal identity information')
              "
              :errorMsg="errorMsg"
            ></personal-information-comp>
            <confirm-comp v-if="checkStep('Confirm Details')"></confirm-comp>
            <payment-comp
              v-if="checkStep('Payment Information')"
            ></payment-comp>
          </div>
        </div>
        <div
          class="row confirm-msg"
          style="margin-top: 20px"
          v-if="alterMessage != ''"
        >
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <div
              class="
                alert alert-custom alert-dotted alert-shadow
                fade
                show
                gutter-b
              "
              role="alert"
            >
              <div class="alert-text">
                <div v-html="alterMessage"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-form-footer
        v-on:next="nextStep"
        v-on:back="back"
        :nextbtnicon="nextBtnIcon"
        :btnname="nextBtn"
        :backname="currentStep > 0 ? 'Back' : ''"
        :totalamount="
          getMobileLocationJobList.job.payment_by == 'Individual'
            ? getTotalRateAmount.toFixed(2)
            : ''
        "
      ></v-form-footer>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import vTitle from "@/components/frontend/title";

import CitizenComp from "./citizen.vue";
import DisclosureComp from "./disclosure.vue";
import FnDisclosureComp from "./fndisclouser.vue";
import BooknowComp from "./booknow.vue";
import FormComp from "./form.vue";
import DrugFormComp from "./drugform.vue";
import ConfirmComp from "./confirm.vue";
import PaymentComp from "./payment.vue";
import PersonalInformationComp from "./personal.vue";
import vFormFooter from "@/components/frontend/formfooter";
import {
  FETCH_MOBILE_LOCATION_JOB,
  STORE_MOBILE_LOCATION_APPOITMENT,
  STORE_APPOINTMENT_APPLICANT_INFO,
  STORE_APPOINTMENT_APPLICANT_INFO_ID,
  STORE_APPLICANT_PERSONAL_INFO,
  STORE_DRUG_APPOINTMENT_APPLICANT_INFO,
  SUCCESS_DRUG_TESTING_PAYEMENT,
  STORE_APPOINTMENT_FROM_APPLICANT,
  MAKE_INVOICE_AFTER_ONLINE_PAYEMENT,
  OTP_VERIFICATION,
  VERIFY_OTP_SMS,
  VERIFY_OTP_EMAIL,
  RESEND_OTP_EMAIL,
  RESEND_OTP_SMS
} from "@/core/services/store/actions.type";
import {
  SET_APPICANTS,
  SET_APPOINTMENT_TIME,
  UPDATE_FIRSTNAME,
  UPDATE_MIDDLENAME,
  UPDATE_LASTNAME,
  UPDATE_EMAIL,
  UPDATE_CONFIRMEMAIL,
  UPDATE_PHONENO,
  UPDATE_ORI,
  UPDATE_ADDRESSLINE1,
  UPDATE_ADDRESSLINE2,
  UPDATE_ZIPCODE,
  UPDATE_REASONFORFINGERPRINT,
  UPDATE_STATE,
  UPDATE_CITY,
  UPDATE_SOCIAL_SECURITY,
  UPDATE_WEIGHT,
  UPDATE_PLACE_OF_BIRTH,
  UPDATE_COUNTRY,
  UPDATE_GENDER,
  UPDATE_RACE,
  UPDATE_EYECOLOR,
  UPDATE_HAIRCOLOR,
  UPDATE_DATEOFBIRTH,
  UPDATE_DATEOFBIRTH_MONTH,
  UPDATE_DATEOFBIRTH_DAY,
  UPDATE_DATEOFBIRTH_YEAR,
  UPDATE_HEIGHT,
  SET_SMS_STATUS,
  UPDATE_ABBREVIATION_CODE,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  SET_APPOINTMENT_ID,
  SET_APPLICATION_INFO_ID,
  SET_APPT_TOKEN,
  SET_F_CLIENT_RATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE_CODE,
  SET_COUNTRY_PHONE_CODE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_QTY,
  SET_IS_DRUG_TESTING,
  SET_SHOW_CAPTURE_OPTION,
  SET_MULTI_RECIPIENT,
  SET_F_ARCHIVAL_CLIENT_RATE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_SERVICE_ORI_CODE,
  SET_WIZARD_FORM_BUTTON_DISABLE,
  SET_OTP_FOR_SMS_VERIFY,
  SET_OTP_FOR_EMAIL_VERIFY
} from "@/core/services/store/mutations.type";
export default {
  data() {
    return {
      totalStep: 6,
      starttime: 0,
      timer: null,
      isRunning: false,
      wizardStep: [],
      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      theValue: "",
      nextBtnIcon: "fa-arrow-right",
      saveDataLoader: false,
      currentStep: 0,
      nextBtn: "Next",
      errorMsg: "",
      loadingMessage: "",
      message: "",
      alterMessage: "",
      disclosureFlag: false,
      appointment_id: 0,
      appointment_token: "",
      applicant_id: 0,
      openOtpmodal: false,
      otp: {
        otp_phone: "",
        is_phone: false,
        otp_email: "",
        is_email: false
      }
    };
  },
  components: {
    vTitle,
    CitizenComp,
    DisclosureComp,
    FormComp,
    DrugFormComp,
    ConfirmComp,
    PaymentComp,
    vFormFooter,
    FnDisclosureComp,
    BooknowComp,
    PersonalInformationComp
  },
  computed: {
    ...mapGetters([
      "getTotalRateAmount",
      "getMobileLocationJobList",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getCitizenTitle",
      "getApplicantFirstName",
      "getApplicantMiddleName",
      "getApplicantlastName",
      "getApplicantEmail",
      "getApplicantConfirmEmail",
      "getApplicantPhoneNo",
      "getApplicantAddressLine1",
      "getApplicantAddressLine2",
      "getApplicantZipCode",
      "getApplicantReason",
      "getApplicantCity",
      "getApplicantState",
      "getApplicantWeight",
      "getApplicantSSN",
      "getApplicantPlaceOfBirth",
      "getApplicantcounrtyOfCitizeb",
      "getApplicantGender",
      "getApplicantRace",
      "getApplicantEyeColor",
      "getApplicantHairColor",
      "getApplicantDateOfBirth",
      "getApplicantHeight",
      "getApplicantId",
      "getSelectedCountryPhoneCode",
      "getApplicantDobMonth",
      "getApplicantDobDay",
      "getApplicantDobYear",
      "getSmsStatus",
      "getMobileAppointApplicants",
      "getPaymentType",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderCity",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderState",
      "getPaymentCardPhonecode",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentCardNumber",
      "getAppointmentId",
      "getApptToken",
      "getServiceTypeCode",
      "getServiceOriCode",
      "getServiceOri",
      "getsmsverification",
      "getemailverification"
    ])
  },
  created() {
    this.saveDataLoader = true;
    this.$store
      .dispatch(FETCH_MOBILE_LOCATION_JOB, this.$route.params.slug)
      .then(data => {
        this.wizardFormSteps(data);
        this.saveDataLoader = false;
      })
      .catch(() => {
        this.saveDataLoader = false;
        this.$router.push({ name: "error.page" });
      });
  },

  methods: {
    tiggerTerm(flag) {
      this.disclosureFlag = flag;
    },
    isValidDate(year, month, day) {
      var d = new Date(year, month - 1, day);
      return month == d.getMonth() + 1;
    },
    reset() {
      this.$store.commit(SET_APPOINTMENT_TIME, []);
      this.$store.commit(UPDATE_FIRSTNAME, "");
      this.$store.commit(UPDATE_MIDDLENAME, "");
      this.$store.commit(UPDATE_LASTNAME, "");
      this.$store.commit(UPDATE_EMAIL, "");
      this.$store.commit(UPDATE_CONFIRMEMAIL, "");
      this.$store.commit(UPDATE_PHONENO, "");
      this.$store.commit(UPDATE_ORI, "");
      this.$store.commit(UPDATE_ADDRESSLINE1, "");
      this.$store.commit(UPDATE_ADDRESSLINE2, "");
      this.$store.commit(UPDATE_ZIPCODE, "");
      this.$store.commit(UPDATE_REASONFORFINGERPRINT, "");
      this.$store.commit(UPDATE_STATE, "");
      this.$store.commit(UPDATE_CITY, "");
      this.$store.commit(UPDATE_SOCIAL_SECURITY, "");
      this.$store.commit(UPDATE_WEIGHT, "");
      this.$store.commit(UPDATE_PLACE_OF_BIRTH, "");
      this.$store.commit(UPDATE_COUNTRY, "United States");
      this.$store.commit(UPDATE_GENDER, "");
      this.$store.commit(UPDATE_RACE, "");
      this.$store.commit(UPDATE_EYECOLOR, "");
      this.$store.commit(UPDATE_HAIRCOLOR, "");
      this.$store.commit(UPDATE_DATEOFBIRTH, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_MONTH, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_DAY, "");
      this.$store.commit(UPDATE_DATEOFBIRTH_YEAR, "");
      this.$store.commit(UPDATE_HEIGHT, "");
      this.$store.commit(SET_SMS_STATUS, false);
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
      this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
      this.$store.commit(SET_PAYMENT_CARD_CITY, "");
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
      this.$store.commit(CARD_COUNTRY, "United States");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
      this.$store.commit(SET_APPOINTMENT_ID, 0);
      this.$store.commit(SET_APPLICATION_INFO_ID, 0);
      this.$store.commit(SET_APPT_TOKEN, 0);
      this.$store.commit(SET_F_CLIENT_RATE, {
        rate: "0",
        additional_rate: "0",
        service: {
          default_qty: 0
        }
      });

      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
      this.$store.commit(SET_APPICANTS, []);
      this.$store.commit(SET_QTY, 0);
      this.$store.commit(SET_IS_DRUG_TESTING, false);
      this.$store.commit(SET_SHOW_CAPTURE_OPTION, false);

      this.$store.commit(SET_MULTI_RECIPIENT, 0);
      this.$store.commit(SET_F_ARCHIVAL_CLIENT_RATE, "");

      // eslint-disable max-len
      var country_phone_code = {
        name: "United States",
        dial_code: "+1",
        code: "US",
        flag:
          // eslint-disable-next-line max-len
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIWSURBVHja1JY/SBthGIefu1xqS6K20KFDy0kopUiHmphIByUZotRAIZOTWZzFpYtbB0uh6KJTIdQhi9pBSwmmCOpgoUSKFItTh4AU6tCr16Rn5P58XZocDrlYuAz9wfHAcbzv9/2+932/k4QQdFIyHVbHE0iAAlwFgj7HNoG6AoRzudc/A4F/28yL2l7bb269yd9QgJAsS8zMjFIufyWRuHspXqtbnsHrH8oAIQlQJyfzlaGhCNFohJ2dI1Kp/iZ3d49IJvsvvJckmJ197JlACIEsy30KgGUJBgcjFIufSacfsLnpza2tL/x4+qx15fR0Uz84hL8HjG1blEqHJJP9bGx8IpMZ8CSAMIzWq1cUhO24CSzLYWTkPisrH8lm46yuenN9fZ+br156WmRZFgQLjR3YrK2VyWSiFAp7TEw88iTAyZNca4t6e6h/P3EbzTRtxscfks9vk83G27JaPcOuVls/v6o4pltlajo9L1KpebG8vC9isbm2jMXmRDsZhiEAVWn4NTU1ysJCkenpMRYXS55cWnrPcSThUUVhzrquNEeFOjz8vOI4CrXa+aU7+d3p29YJusMYwQD3Drb7AFRd14Xf0nXdtehbfAxdkhG13/5M0HCImiTcPhC2BVIAHMefOWrbCNxYqqZpvlukaVrTIrNye4CK1JH7xpSAXuAOcN3n4KfAceNG62qch4+ygHPpv/+r+DMAXV79BpyNnBoAAAAASUVORK5CYII="
      };
      this.$store.commit(
        SET_PAYMENT_CARD_NUMBER_PHONE_CODE,
        country_phone_code
      );
      this.$store.commit(SET_COUNTRY_PHONE_CODE, country_phone_code);
    },
    wizardFormSteps(data) {
      if (data.rates.service.service_type == "citizen") {
        this.wizardStep = [
          {
            title: "Citizenship Status",
            active_status: false,
            current_status: true
          },
          {
            title: "APPLICANT RIGHT AND DISCLAIMERS",
            active_status: false,
            current_status: false
          }
        ];
      } else if (data.rates.service.service_type == "DISCLOSURE") {
        this.wizardStep = [
          {
            title: "DISCLOSURE",
            active_status: false,
            current_status: false
          }
        ];
      }

      this.wizardStep.push(
        {
          title: "Booknow",
          active_status: false,
          current_status: false
        },
        {
          title: "Contact Information",
          active_status: false,
          current_status: false
        },

        {
          title: "Contact Information_2",
          active_status: false,
          current_status: false
        },
        {
          title: "Personal identity information",
          active_status: false,
          current_status: false
        },
        {
          title: "Confirm Details",
          active_status: false,
          current_status: false
        },
        {
          title: "Payment Information",
          active_status: false,
          current_status: false
        }
      );

      this.wizardStep[0].current_status = true;

      this.messageTitle(this.wizardStep[0].title);

      if (data.rates.service.is_drug_testing) {
        var key = this.wizardStep.findIndex(
          x => x.title == "Personal identity information"
        );

        var key1 = this.wizardStep.findIndex(
          x => x.title == "Contact Information"
        );
        this.wizardStep.splice(key, 1);
        this.wizardStep.splice(key1, 1);
      } else {
        var key2 = this.wizardStep.findIndex(
          x => x.title == "Contact Information_2"
        );
        this.wizardStep.splice(key2, 1);
      }
    },

    paymentSubmit() {
      if (this.getPaymentType != "CP") {
        var mytoken = document.getElementById("mytoken");
        mytoken = mytoken.innerHTML;
      }
      if (this.getPaymentType == "C") {
        this.loadingMessage =
          "please wait <br> While we process your payment...";
        if (mytoken == "" || mytoken == null) {
          this.$toastr.e(
            "The card number entered is not compliant, please check and re-enter."
          );
          return;
        } else {
          if (
            this.getMobileLocationJobList.service_provider.payment_method ==
            "heartland"
          ) {
            var expiremonth2 = document.getElementById("expiremonth").innerHTML;
            var expireyear2 = document.getElementById("expireyear").innerHTML;

            if (
              mytoken == "" ||
              mytoken == null ||
              expiremonth2 == "" ||
              expiremonth2 == null ||
              expireyear2 == "" ||
              expireyear2 == null
              // this.getPaymentCardCvvNumber == ""
            ) {
              this.$toastr.e("Card info is required");
              return;
            } else {
              if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
                this.SubmitDrugForm();
              } else {
                this.SubmitForm();
              }
            }
          } else {
            if (
              mytoken == "" ||
              mytoken == null ||
              this.getPaymentCardHolderName == "" ||
              this.getPaymentCardExpiry == "" ||
              this.getPaymentCardCvvNumber == ""
            ) {
              this.$toastr.e("Card info is required");
              return;
            } else {
              if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
                this.SubmitDrugForm();
              } else {
                this.SubmitForm();
              }
            }
          }
        }
      } else {
        this.loadingMessage =
          "please wait <br> Your appointment is being scheduled...";
        if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
          this.SubmitDrugForm();
        } else {
          this.SubmitForm();
        }
      }
    },
    cForNext() {

      this.wizardStep[this.currentStep].active_status = true;
      this.wizardStep[this.currentStep].current_status = false;
      this.currentStep += 1;
      this.wizardStep[this.currentStep].current_status = true;
      this.messageTitle(this.wizardStep[this.currentStep].title);
      if (this.currentStep == this.wizardStep.length - 1) {
        this.nextBtn = "Submit";
        this.nextBtnIcon = "fa-check";
      } else if (this.currentStep == this.wizardStep.length - 2) {
        this.nextBtn = "Confirm";
        this.nextBtnIcon = "fa-check";
      }
    },

    nextStep() {
     
      if(this.getMobileLocationJobList.rates.service.service_type_code == 'fdle'){

        if(this.getServiceOriCode.data.ori_number){
          var title = this.wizardStep[this.currentStep].title;
      this.conditonForNext(title);
        }
        else{
          this.$toastr.e("Please use ORI first")
        }
      }
      else{
         title = this.wizardStep[this.currentStep].title;
      this.conditonForNext(title);
      }
      
    },
    SubmitForm() {
      this.errorMsg = "";
      this.saveDataLoader = true;
      let updateAbbreviationCode = "";

      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        appointment_id: this.appointment_id,
        applicant_id: this.getApplicantId,
        abbreviation_code: updateAbbreviationCode,
        sms_status: this.getSmsStatus,
        appt_token: this.appointment_token,

        time: {
          start: this.timeFormat(
            this.getMobileLocationJobList.job.schedule_time,
            this.getMobileLocationJobList.job.schedule_date
          )
        }
      };
      var invoice_details = [];

      // var rate = parseFloat(this.getfClientRate.rate);
      // var taxAmount = (
      //   (parseFloat(this.getfClientRate.tax_percentage) / 100) *
      //   rate
      // ).toFixed(2);
      // var totalAmount = parseFloat(
      //   parseFloat(rate) + parseFloat(taxAmount)
      // ).toFixed(2);
      var data_1 = {
        qty: parseInt(this.getMobileLocationJobList.rates.service.default_qty),
        // rate: rate,
        // code_: this.getfClientRate.commission_percentage,
        is_additional: 0,
        // tax_amount: taxAmount,
        // total_amount: totalAmount,
        title:
          "Service Name: " + this.getMobileLocationJobList.rates.service.name
      };
      invoice_details.push(data_1);

      if (this.getPaymentType == "C") {
        var token = mytoken.innerHTML;
        if (
          this.getMobileLocationJobList.service_provider.payment_method ==
          "heartland"
        ) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
            // card_city: this.getPaymentCardHolderCity,
            // card_state: this.getPaymentCardHolderState
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,
            // amount: this.toFixedTwoDigit_(
            //   this.getTotalRateAmount,
            //   this.getfClientRate
            // ),
            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }
      // if (this.getPaymentType != "C") {
      formData.invoice_details = invoice_details;
      // }

      this.$store
        .dispatch(STORE_APPOINTMENT_FROM_APPLICANT, {
          url:
            this.getPaymentType == "C"
              ? "appointment/applicant/payment"
              : "submit/appointment/applicant/",
          data: formData
        })
        .then(res => {
          if (this.getPaymentType != "C") {
            this.saveDataLoader = false;
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: res.appointment_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                card_token: "",
                appt_token: this.appointment_token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
                return;
              });
            this.$toastr.s(res.msg);
            // this.$store.commit(SET_APPOINTMENT_ID, res.appointment_id);
            // this.reset();
            this.$router.replace({
              name: "client.appointment.view",
              params: {
                client_slug: this.getMobileLocationJobList.location.slug
              },
              query: {
                token: res.token,
                app_id: res.appointment_id,
                route_name: "mobile.location.reg",
                slug: this.$route.params.slug
              }
            });
            // window.location.reload();
          } else {
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: res.appointment_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                // card_token: res.card_token,
                card_token: "",
                appt_token: this.appointment_token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                this.$toastr.e(errs.msg);
                this.errorMsg = errs.msg;
                return;
              });
            this.$toastr.s(res.msg);

            // this.reset();
            this.$router.replace({
              name: "client.appointment.view",
              params: {
                client_slug: this.getMobileLocationJobList.location.slug
              },
              query: {
                token: res.token,
                app_id: res.appointment_id,
                route_name: "mobile.location.reg",
                slug: this.$route.params.slug
              }
            });
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          // console.log(errs)
          this.errorMsg = errs.msg;
          return;
        });
    },
    timeFormat(value, date) {
      if (value == "") {
        return "";
      }
      var fullDate = date + " " + value;
      return this.$moment(fullDate).format("hh:mm a");
    },

    SubmitDrugForm() {
      // alert();
      this.errorMsg = "";
      this.saveDataLoader = true;
      let updateAbbreviationCode = "";
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        abbreviation_code: updateAbbreviationCode,
        sms_status: this.getSmsStatus,
        selected_appointment: this.getMobileAppointApplicants,
        qty: parseInt(this.getMobileLocationJobList.rates.service.default_qty),
        time: {
          start: this.timeFormat(
            this.getMobileLocationJobList.job.schedule_time,
            this.getMobileLocationJobList.job.schedule_date
          )
        }
      };

      if (this.getPaymentType == "C") {
        var token = mytoken.innerHTML;
        if (
          this.getMobileLocationJobList.service_provider.payment_method ==
          "heartland"
        ) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,

            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }
      this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);

      this.$store
        .dispatch(STORE_APPOINTMENT_FROM_APPLICANT, {
          url:
            this.getPaymentType == "C"
              ? "store/drug-testing/submit/appointment/payment/"
              : "store/drug-testing/submit/appointment/",
          data: formData
        })
        .then(res => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          if (this.getPaymentType != "C") {
            // this.saveDataLoader = false;
            this.$store
              .dispatch(SUCCESS_DRUG_TESTING_PAYEMENT, {
                appointment_id: res.appointment_id,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                card_token: "",
                appt_token: res.token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
                return;
              });
            this.$toastr.s(res.msg);
            // this.$store.commit(SET_APPOINTMENT_ID, res.appointment_id);
            // this.reset();
            this.$router.replace({
              name: "client.appointment.view",
              params: {
                client_slug: this.getMobileLocationJobList.location.slug
              },
              query: {
                token: res.token,
                app_id: res.appointment_id,
                route_name: "mobile.location.reg",
                slug: this.$route.params.slug
              }
            });
            // window.location.reload();
          } else {
            this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);
            this.$store
              .dispatch(SUCCESS_DRUG_TESTING_PAYEMENT, {
                appointment_id: res.appointment_id,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                // card_token: res.card_token,
                card_token: "",
                appt_token: res.token,
                invoice_id: res.invoice_id
              })
              .then(() => {
                this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
                // this.$toastr.s(data.msg);
                this.saveDataLoader = false;
                this.reset();
              })
              .catch(errs => {
                this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
                return;
              });
            this.$toastr.s(res.msg);

            // this.reset();
            this.$router.replace({
              name: "client.appointment.view",
              params: {
                client_slug: this.getMobileLocationJobList.location.slug
              },
              query: {
                token: res.token,
                app_id: res.appointment_id,
                route_name: "mobile.location.reg",
                slug: this.$route.params.slug
              }
            });
          }
        })
        .catch(errs => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          // console.log(errs)
          this.errorMsg = errs.msg;
          return;
        });
    },

    storeMobileLocationAppointment() {
      this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);
      this.$store
        .dispatch(STORE_MOBILE_LOCATION_APPOITMENT, {
          id: parseInt(this.appointment_id),
          schedule_date: this.getMobileLocationJobList.job.schedule_date,
          schedule_time: this.getMobileLocationJobList.job.schedule_time,
          client: this.getMobileLocationJobList.job.client,
          abbreviation_code: this.getAbbreviationCode.success
            ? this.updateAbbreviationCode
            : "",
          citizen_type: this.getCitizenTitle,
          service: this.getMobileLocationJobList.job.service,
          token: this.appointment_token,
          archival_period: "",
          location_job: this.getMobileLocationJobList.job.id,
          service_ori: this.getServiceOri
        })
        .then(data => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          this.appointment_id = data.appointment_id;
          this.appointment_token = data.appt_token;
          this.getMobileAppointApplicants[0].id = data.appointment_id;
          this.getMobileAppointApplicants[0].appt_token = data.appt_token;
          this.cForNext();
        })
        .catch(() => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          return;
        });
    },

    storeContactInformation() {
      if (
        this.getApplicantFirstName == "" ||
        this.getApplicantlastName == "" ||
        this.getApplicantEmail == "" ||
        this.getApplicantConfirmEmail == "" ||
        this.getApplicantPhoneNo == "" ||
        this.getApplicantAddressLine1 == "" ||
        this.getApplicantZipCode == "" ||
        this.getApplicantCity == "" ||
        this.getApplicantCity == null ||
        this.getApplicantState == "" ||
        this.getApplicantState == null ||
        this.getApplicantReason == ""
      ) {
        this.$toastr.e("Please enter all mandatory fields ");
        return;
      } else {
        if (!this.regEmail.test(this.getApplicantEmail)) {
          this.$toastr.e("Please enter a valid email address");
          return;
        } else {
          if (this.getApplicantEmail != this.getApplicantConfirmEmail) {
            this.$toastr.e("Email and confirm email doesn't matched");
            return;
          } else {
            if (this.getApplicantPhoneNo.length != 14) {
              this.$toastr.e("Please enter valid phone number");
              return;
            } else {
              if (this.getApplicantZipCode.length != 5) {
                this.$toastr.e("Please enter valid zipcode");
                return;
              } else {
                this.storeApplicant();
              }
            }
          }
        }
      }
    },

    personalInformation() {
      if (
        this.getApplicantDobMonth == null ||
        this.getApplicantDobMonth == "" ||
        this.getApplicantDobDay == null ||
        this.getApplicantDobDay == "" ||
        this.getApplicantDobYear == null ||
        this.getApplicantDobYear == "" ||
        this.getApplicantPlaceOfBirth == "" ||
        this.getApplicantcounrtyOfCitizeb == "" ||
        this.getApplicantGender == "" ||
        this.getApplicantRace == "" ||
        this.getApplicantRace == null ||
        this.getApplicantEyeColor == "" ||
        this.getApplicantHairColor == "" ||
        this.getApplicantEyeColor == null ||
        this.getApplicantHairColor == null ||
        this.getApplicantHeight == "" ||
        this.getApplicantWeight == ""
      ) {
        this.$toastr.e("Please enter all mandatory fields");
        return;
      } else {
        if (
          !this.isValidDate(
            this.getApplicantDobYear,
            this.getApplicantDobMonth,
            this.getApplicantDobDay
          )
        ) {
          this.$toastr.e("Please Enter valid Date of birth");
        } else {
          this.storeApplicantPersonalIdentity();
        }
      }
    },
    drugForm() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      let applicant = this.getMobileAppointApplicants;
      this.saveDataLoader = true;
      if (applicant[0].applicant.id != "") {
        this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
          applicant_id: applicant[0].applicant.id
        });
      }
      var OtpformData = {
        client_slug: this.getMobileLocationJobList.service_provider.slug,
        phone:
          this.getSelectedCountryPhoneCode.dial_code +
          applicant[0].applicant.phone_no,
        email: applicant[0].applicant.email,
        applicant_id: this.getApplicantId,
        app_id: applicant[0].id
      };
      this.$store
        .dispatch(OTP_VERIFICATION, {
          url: "send/appointment-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (
            res.data.phone_verified == false ||
            res.data.email_verified == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.drugAfterOtpForm();
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },

    drugAfterOtpForm() {
      this.errorMsg = "";
      this.saveDataLoader = true;
      var applicant = this.getMobileAppointApplicants;
      // console.log(applicant[0].applicant.phone_no);
      applicant[0].applicant.dial_code = this.getSelectedCountryPhoneCode.dial_code;
      this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);
      // console.log(applicant);
      this.$store
        .dispatch(STORE_DRUG_APPOINTMENT_APPLICANT_INFO, {
          data: [...applicant]
        })
        .then(res => {
          this.saveDataLoader = false;
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          if (res.code == 200) {
            this.getMobileAppointApplicants[0].applicant.id =
              res.selected_appointments[0].applicant.id;
            // this.$toastr.s(res.msg);
            this.cForNext();
          } else {
            this.$toastr.w(res.msg);
            return;
          }
        })
        .catch(errs => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
          return;
        });
    },

    conditonForNext(title) {
      // alert(title);
      switch (title) {
        case "APPLICANT RIGHT AND DISCLAIMERS":
          if (!this.disclosureFlag) {
            this.$toastr.e("Plase accept both terms and conditions");
            return;
          }
          this.cForNext();
          break;

        case "DISCLOSURE":
          if (!this.disclosureFlag) {
            this.$toastr.e("Plase accept both terms and conditions");
            return;
          }
          this.cForNext();
          break;

        case "Booknow":
          this.storeMobileLocationAppointment();
          break;

        case "Contact Information_2":
          this.drugForm();

          break;

        case "Contact Information":
          this.storeContactInformation();
          break;

        case "Personal identity information":
          this.personalInformation();

          break;

        case "Confirm Details":
          //  flag:this.getSmsStatus!=''?true:false;
          if (this.getSmsStatus == false) {
            this.$toastr.e("Please confirm the given details ");
            return;
          } else {
            this.cForNext();
          }
          break;

        case "Payment Information":
          this.paymentSubmit();
          break;
        default:
          this.cForNext();
          break;
      }
    },

    back() {
      if (this.currentStep == 0) {
        return;
      }

      this.wizardStep[this.currentStep].active_status = false;
      this.wizardStep[this.currentStep].current_status = false;
      this.currentStep -= 1;
      this.wizardStep[this.currentStep].current_status = true;
      this.wizardStep[this.currentStep].active_status = false;
      this.messageTitle(this.wizardStep[this.currentStep].title);
      if (this.currentStep == this.wizardStep.length - 1) {
        this.nextBtn = "Submit";
        this.nextBtnIcon = "fa-check";
      } else if (this.currentStep == this.wizardStep.length - 2) {
        this.nextBtn = "Confirm";
        this.nextBtnIcon = "fa-check";
      } else {
        this.nextBtn = "Next";
        this.nextBtnIcon = "fa-arrow-right";
      }
    },

    checkStep(title) {
      var key = this.wizardStep.findIndex(x => x.title == title);

      if (this.currentStep == key) {
        return true;
      }
      return false;
    },
    storeApplicantPersonalIdentity() {
      this.errorMsg = "";
      // this.saveDataLoader = true;
      var formData = {
        appointment_id: this.appointment_id,
        applicant_id: this.getApplicantId,
        form_data: {
          ssn: this.getApplicantSSN,
          dob: this.getApplicantDateOfBirth,
          place_of_birth: this.getApplicantPlaceOfBirth,
          country_of_citizenship: this.getApplicantcounrtyOfCitizeb,
          gender: this.getApplicantGender,
          race: this.getApplicantRace,
          eye_color: this.getApplicantEyeColor,
          hair_color: this.getApplicantHairColor,
          height: this.getApplicantHeight,
          weight: this.getApplicantWeight
        }
      };
      this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);

      this.$store
        .dispatch(STORE_APPLICANT_PERSONAL_INFO, {
          url: "store/new/next/appointment/applicant/",
          data: formData
        })
        .then(res => {
          // this.saveDataLoader = false;
          if (res.code == 200) {
            this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
            // this.$toastr.s(res.msg);
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.applicant_id
            });
            this.cForNext();
          }
        })
        .catch(errs => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          // this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
          return;
        });
    },
    storeDrugApplicant() {},
    ResendEmailOtp() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      // let applicant = this.getMobileAppointApplicants;
      if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
        var OtpformData = {
          email: this.getMobileAppointApplicants[0].applicant.email,
          app_id: this.getMobileAppointApplicants[0].id,
          client_slug: this.getMobileLocationJobList.service_provider.slug,
          applicant_id: this.getApplicantId
        };
      } else {
        OtpformData = {
          email: this.getApplicantEmail,
          app_id: this.getAppointmentId,
          client_slug: this.getMobileLocationJobList.service_provider.slug
        };
      }
      this.$store
        .dispatch(RESEND_OTP_EMAIL, {
          url: "resend/appointment/email-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.saveDataLoader = false;
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    ResendPhoneOtp() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getMobileAppointApplicants[0].applicant.phone_no,
          app_id: this.getMobileAppointApplicants[0].id,
          client_slug: this.getMobileLocationJobList.service_provider.slug,
          applicant_id: this.getApplicantId
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          app_id: this.getAppointmentId,
          client_slug: this.getMobileLocationJobList.service_provider.slug
        };
      }
      this.$store
        .dispatch(RESEND_OTP_SMS, {
          url: "resend/appointment/phone-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.saveDataLoader = false;
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    resetotp() {
      this.otp = {
        otp_phone: "",
        is_phone: false,
        otp_email: "",
        is_email: false
      };
    },
    otpverify() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.otp.otp_phone == "") {
        this.otp.is_phone = true;
        this.saveDataLoader = false;
        return 0;
      }
      if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getMobileAppointApplicants[0].applicant.phone_no,
          email: this.getMobileAppointApplicants[0].applicant.email,
          applicant_id: this.getApplicantId,
          app_id: this.getMobileAppointApplicants[0].id,
          otp: this.otp.otp_phone
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          email: this.getApplicantEmail,
          applicant_id: this.getApplicantId ? this.getApplicantId : 0,
          otp: this.otp.otp_phone,
          app_id: this.appointment_id
        };
      }
      this.$store
        .dispatch(VERIFY_OTP_SMS, {
          url: "verify/appointment/phone-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          } else {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          }

          if (
            res.data.phone_verified == true &&
            this.getemailverification == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.openOtpmodal = false;
            this.resetotp();
            if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
              this.storeDrugApplicantAfterOtp();
            } else {
              this.storeapp();
            }
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    otpemailverify() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      if (this.otp.otp_email == "") {
        this.otp.is_email = true;
        this.saveDataLoader = false;
        return 0;
      }
      if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
        var OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getMobileAppointApplicants[0].applicant.phone_no,
          email: this.getMobileAppointApplicants[0].applicant.email,
          applicant_id: this.getApplicantId,
          app_id: this.getMobileAppointApplicants[0].id,
          otp: this.otp.otp_email
        };
      } else {
        OtpformData = {
          phone:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          email: this.getApplicantEmail,
          applicant_id: this.getApplicantId ? this.getApplicantId : 0,
          otp: this.otp.otp_email,
          app_id: this.appointment_id
        };
      }
      this.$store
        .dispatch(VERIFY_OTP_EMAIL, {
          url: "verify/appointment/email-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          } else {
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.data.applicant_id
            });
          }
          if (res.data.email_verified == false) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.openOtpmodal = false;
            this.resetotp();
            if (this.getMobileLocationJobList.rates.service.is_drug_testing) {
              this.storeDrugApplicantAfterOtp();
            } else {
              this.storeapp();
            }
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e(errs.msg);
          this.errorMsg = errs;
        });
    },
    storeApplicant() {
      this.errorMsg = [];
      this.loadingMessage = "please wait...";
      this.saveDataLoader = true;
      var OtpformData = {
        client_slug: this.getMobileLocationJobList.service_provider.slug,
        phone:
          this.getSelectedCountryPhoneCode.dial_code + this.getApplicantPhoneNo,
        email: this.getApplicantEmail,
        applicant_id: this.getApplicantId ? this.getApplicantId : 0,
        app_id: this.appointment_id
      };
      this.$store
        .dispatch(OTP_VERIFICATION, {
          url: "send/appointment-otp/",
          data: OtpformData
        })
        .then(res => {
          this.$store.commit(SET_OTP_FOR_SMS_VERIFY, res.data.phone_verified);
          this.$store.commit(SET_OTP_FOR_EMAIL_VERIFY, res.data.email_verified);
          if (
            res.data.phone_verified == false ||
            res.data.email_verified == false
          ) {
            this.openOtpmodal = true;
            this.saveDataLoader = false;
          } else {
            this.storeapp();
          }
        })
        .catch(errs => {
          this.saveDataLoader = false;
          this.$toastr.e("Something went wrong");
          this.errorMsg = errs;
        });
    },
    storeapp() {
      this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, true);
      this.errorMsg = "";
      this.saveDataLoader = true;
      var formData = {
        appointment_id: this.appointment_id,

        applicant_id: this.getApplicantId ? this.getApplicantId : 0,
        form_data: {
          fname: this.getApplicantFirstName,
          mname: this.getApplicantMiddleName,
          lname: this.getApplicantlastName,
          email: this.getApplicantEmail,
          phone_no:
            this.getSelectedCountryPhoneCode.dial_code +
            this.getApplicantPhoneNo,
          address1: this.getApplicantAddressLine1,
          address2: this.getApplicantAddressLine2,
          city: this.getApplicantCity,
          zipcode: this.getApplicantZipCode,
          reason: this.getApplicantReason,
          state: this.getApplicantState,
          ori: this.getApplicantOri
        }
      }; // console.log(formData);

      this.$store
        .dispatch(STORE_APPOINTMENT_APPLICANT_INFO, {
          url: "store/new/appointment/applicant/",
          data: formData
        })
        .then(res => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          this.saveDataLoader = false;
          if (res.code == 200) {
            // this.$toastr.s(res.msg);
            this.$store.dispatch(STORE_APPOINTMENT_APPLICANT_INFO_ID, {
              applicant_id: res.applicant_id
            });
            this.$store.commit(SET_APPICANTS, [
              {
                appt_token: res.token,
                id: res.applicant_id
              }
            ]);
            this.cForNext();
          } else {
            this.$toastr.w(res.msg);
            return;
          }
        })
        .catch(errs => {
          this.$store.commit(SET_WIZARD_FORM_BUTTON_DISABLE, false);
          this.saveDataLoader = false;
          this.$toastr.e("Please enter all mandatory fields");
          this.errorMsg = errs;
          return;
        });
    },

    messageTitle(title) {
      this.message = "";
      this.alterMessage = "";
      if (title == "Booknow") {
        this.message = this.getMobileLocationJobList.rates.service.book_now_note;
      } else if (title == "Confirm Details") {
        this.message = this.getMobileLocationJobList.notes.appointment_confirm_detail_note;
        this.alterMessage = this.getMobileLocationJobList.notes.appointment_shipping_note;
      } else if (title == "Personal identity information") {
        this.message = this.getMobileLocationJobList.notes.personal_identity_note;
      }
    }
  }
};
</script>
