<style lang="scss">
#f-book-now {
  .btn {
    border-radius: 0;
  }
  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
  .drug-testing-booknow {
    margin-top: 3%;
  }
}
</style>
<template>
  <section v-if="!loadingData">
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-5">
        <h4>{{ getMobileLocationJobList.rates.title }}</h4>
      </div>
      <div class="row" style="margin-top: 15px">
        <div class="col-md-12 col-12">
          <div
            v-if="
              !getServiceOriCode.success &&
                service_ori_code_error &&   
                updateServiceOriCode != '' 
            "
            class="col-md-6 
            alert alert-custom alert-danger-dim alert-shadow
            fade
            show
            gutter-b
          "
            role="alert"
          >
            <div class="alert-text" v-html="getServiceOriCode.msg"></div>
          </div>
        </div>
        <div
          class="col-lg-12 col-md-12 abbre-code  "
          v-if="
            getMobileLocationJobList.rates.service.service_type_code == 'fdle'
          "
        >
          <label class=" pl-4"
            >ORI :
            <strong
              class="text-uppercase"
              onchange="change_ori"
              v-if="
                getServiceOriCode.success == true &&
                  service_ori_code_error &&
                  updateServiceOriCode != ''
              "
              >{{ updateServiceOriCode }}</strong
            >
          </label>
          <div
            class="input-group mb-3 "
            :hidden="getServiceOriCode.success == true"
          >
            <input
              class="form-control text-uppercase"
              v-model="service_ori"
              type="text"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              @input="updateOri"
            />
            <div class="input-group-append">
              <button
                class="btn btn-success"
                @click="checkServiceOri()"
                type="button"
                style="z-index: 0"
              >
                Use
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 abbre-code"
          v-if="
            getServiceOriCode.success == true &&
              service_ori_code_error &&
              updateServiceOriCode != ''
          "
          style="margin-top: 10px"
        >
     
            <div class="col-md-5">
              <ul style="list-style: none; padding: 0">
                <li>
                  Name :
                  <b>{{ getServiceOriCode.data.profession }}</b>
                </li>

                <li class="mt-2">
                  <a
                    style="cursor: pointer"
                    @click="removeServiceOri()"
                    class="btn btn-danger"
                  >
                    <i class="fa fa-times"></i> Remove ORI
                  </a>
                </li>
              </ul>
            </div>
        
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 mb-5" v-if="getMobileLocationJobList.rates.service.service_type_code != 'fdle'">
        <table class="table table-bg-success">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                {{ getMobileLocationJobList.rates.service.default_qty }}
              </th>
              <td class="text-right">
                ${{ getMobileLocationJobList.rates.rate }}
              </td>
              <td
                class="text-right"
                v-if="getMobileLocationJobList.rates.service.is_drug_testing"
              >
                ${{
                  getMobileLocationJobList.rates.rate
                    | taxAmount(getMobileLocationJobList.rates.tax_percentage)
                }}
              </td>
              <td class="text-right" v-else>
                ${{
                  getMobileLocationJobList.rates.rate
                    | taxAmount(getMobileLocationJobList.rates.tax_percentage)
                }}
              </td>

              <!-- <td class="text-right">${{ getMobileLocationJobList | toFixedTwoDigitRate }}</td> -->
              <td
                class="text-right"
                v-if="getMobileLocationJobList.rates.service.is_drug_testing"
              >
                ${{ getTotalRateAmount.toFixed(2) }}
                <!-- ${{getItemTotalAmount}} -->
              </td>
              <td class="text-right" v-else>
                ${{ getTotalRateAmount.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-12 mb-5" v-if="getMobileLocationJobList.rates.service.service_type_code == 'fdle'">
        <table class="table table-bg-success" v-if="getServiceOriCode.data.rate">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="text-right">
                1
              </td>
              <td class="text-right">
                ${{ getServiceOriCode.data.rate }}
              </td>
              <td
                class="text-right"
              >
              ${{ getServiceOriCode.data.tax_percentage }}
              </td>
              <td class="text-right" >

                  ${{ getTotalRateAmount.toFixed(2) }}
              </td>

              <!-- <td class="text-right">${{ getMobileLocationJobList | toFixedTwoDigitRate }}</td> -->
             
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="row"
      v-if="getServiceOriCode.data.rate"
    >
      <h5 >
        Total: ${{ getTotalRateAmount }}
      </h5>
    </div>
  </section>
</template>
<script>
// import Title from '../../components/frontend/title.vue';
import { CHECK_F_SERVICE_ORI_CODE } from "@/core/services/store/actions.type";
import {
  SET_RATE_TOTAL_AMOUNT,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_ORI
} from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
export default {
  data() {
    return { a: "sss", service_ori: "", service_ori_code_error: false };
  },
  computed: {
    ...mapGetters([
      "getMobileLocationJobList",
      "getTotalRateAmount",
      "getServiceTypeCode",
      "getServiceOriCode",
      "updateServiceOriCode"
    ]),
    getTotalRateAmount() {
    if (this.getMobileLocationJobList.rates.service.service_type_code != 'fdle') {
      var rate =
        parseFloat(this.getMobileLocationJobList.rates.rate) +
        (parseFloat(this.getMobileLocationJobList.rates.rate) *
          parseFloat(this.getMobileLocationJobList.rates.tax_percentage)) /
        100;
        
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, rate);
      return rate;
    } else {
      var rate_ori = parseFloat(parseFloat(this.getServiceOriCode.data.tax_percentage) + parseFloat(this.getServiceOriCode.data.rate));
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, rate_ori);
      return rate_ori;
    }
  }
},
  mounted() {
    this.$emit("startclock", 1);
    if (this.updateServiceOriCode != "") {
      this.service_ori = this.updateServiceOriCode;
      this.checkServiceOri();
    }
  },

  filters: {
    taxAmount(amount, taxPer) {
      amount = parseFloat(amount);
      taxPer = parseFloat(taxPer);
      return ((amount * taxPer) / 100).toFixed(2);
    }
  },
  methods: {
    updateOri(e) {
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, e.target.value);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    change_ori() {
      this.$store.commit(UPDATE_ORI, "");
      this.service_ori_code_error = true;
    },
    removeServiceOri() {
      this.$store.commit(UPDATE_ORI, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.service_ori_code_error = false;
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
    },
    checkServiceOri() {
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, this.updateServiceOriCode);
      if (this.updateServiceOriCode != "") {
        this.service_ori_code_error = true;
        this.$store
          .dispatch(CHECK_F_SERVICE_ORI_CODE, {
            code: this.updateServiceOriCode,
            service_code:'fdle',
            client:this.getMobileLocationJobList.location.id,
            service:this.getMobileLocationJobList.job.service
          })
          .then(data => {
            if (data.success) {
              this.$store.commit(UPDATE_SERVICE_ORI_CODE, data.data.ori_number);
              this.$store.commit(UPDATE_ORI, data.data.id);
              


              this.service_ori = this.updateServiceOriCode;
            } else {
              this.service_ori_code_error = true;
            }
          });
      } else {
        this.service_ori_code_error = true;
      }
    }
  }
};
</script>
